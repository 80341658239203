@font-face {
  font-family: 'RijksoverheidSansWebText';
  font-style: normal;
  src: url("../assets/fonts/ROsanswebtextregular.woff") format('woff');
}

@font-face {
  font-family: 'RijksoverheidSansWebText';
  font-style: italic;
  src: url("../assets/fonts/ROsanswebtextitalic.woff") format('woff');
}

@font-face {
  font-family: 'RijksoverheidSansWebText';
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/ROsanswebtextbold.woff") format('woff');
}

@font-face {
  font-family: 'RijksoverheidSerifWeb';
  font-style: normal;
  src: url("../assets/fonts/ROserifwebregular.woff") format('woff');
}

@font-face {
  font-family: 'RijksoverheidSerifWeb';
  font-style: italic;
  src: url("../assets/fonts/ROserifwebitalic.woff") format('woff');
}

@font-face {
  font-family: 'RijksoverheidSerifWeb';
  font-style: normal;
  font-weight: bold;
  src: url("../assets/fonts/ROserifwebbold.woff") format('woff');
}
