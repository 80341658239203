$font-family-base: "RijksoverheidSansWebText" !default;
// $font-size-base: 1.125rem !default;

$headings-margin-bottom: 0.625rem !default;
$paragraph-margin-bottom: 0.625rem !default;

// if heading comes after other text:
p, ul {
  + h1, + h2, + h3, + h4, + h5, + h6 {
    margin-top: 1.875rem;
  }
}

p:last-child {
  margin-bottom: 0;
}

.fs-btn {
  font-size: 1.125rem !important;
}

.fs-small {
  font-size: 0.875rem !important;
}
